import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { useTranslation, getI18n } from "react-i18next";
import moment from 'moment';
import tz from "moment-timezone"

function Footer() {
    const { t } = useTranslation();
    const [data, setdata] = useState([]);
    const langg = getI18n().language;

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_MAIN_URL}safety/mostview/`).then((response) => {
            setdata(response.data)
        })
    }, [])
    return (
        <>
            <footer>
                <div className="footer-top primary-bg pt-115 pb-90 flow">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="footer-contact-info mb-30">
                                    <div className="emmergency-call fix">
                                        <div className="emmergency-call-icon f-left">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        <div className="emmergency-call-text f-left">
                                            <h6>{t("emergincynum")}</h6>
                                            <span>{t("enum")}</span>
                                        </div>
                                    </div>
                                    <div className="footer-logo mb-35">
                                        <a href="#!"><img src={langg == 'ku' ? "img/logo/logo.jpg" : "img/logo/logoiq.jpg"} alt="" /></a>
                                    </div>
                                    <div className="footer-contact-content mb-25">
                                        <p>
                                            {t("moto")}
                                        </p>
                                    </div>
                                    <div className="footer-emailing " >
                                        <ul>
                                            <li><i className="far fa-envelope"></i>rohalhayatcenterads@gmail.com</li>
                                            <li><i className="far fa-clone"></i>rohalhayat.com</li>
                                            <li><i className="far fa-flag"></i>Iraq, Bagdad</li>
                                            <li><i className="far fa-flag"></i>Iraq, Erbil</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 offset-xl-1 col-lg-3 d-md-none d-lg-block">
                                <div className="footer-widget mb-30">
                                    <div className="footer-title">
                                        <h3>{t("links")}</h3>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><a href="/">{t("home")}</a></li>
                                            <li><a href="/about">{t("about")}</a></li>
                                            <li><a href="/contact">{t("call")}</a></li>
                                            <li><a href="/blog">{t("blog")}</a></li>
                                            {/* <li><a href="#">{t("links")}</a></li> */}
                                            {/* <li><a href="#"></a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6">

                                <div className="footer-widget mb-40">
                                    <div className="footer-title">
                                        <h3>{t("mostview")}</h3>
                                    </div>
                                    <div className="blog-feeds pr-15">


                                        {
                                            data.map(e => {
                                                return <div key={e.id} className="signle-blog-feeds mb-20">
                                                    <div className="blog-feeds-thumb">

                                                    </div>
                                                    <div className="blog-feeds-text">
                                                        <h5>
                                                            <a href={`/blog/${e.id}`}
                                                            > {e.title}</a>
                                                        </h5>
                                                        <span className="feeds-date"> {moment.utc(e.dateAdd).tz('Asia/Baghdad').format("MMM D ")}</span>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-bottom pt-25 pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="footer-copyright text-center">
                                    <p className="white-color">Designed And Developed By @Zhir Aryan</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer