import React from 'react'
import { useTranslation } from 'react-i18next'
import Service from '../Service';
function Abouts() {

    const { t } = useTranslation()

    return (
        <>

            <section className="about-area pt-120 pb-90 flow">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="about-left-side pos-rel mb-30">
                                <div className="about-front-img pos-rel">
                                    <img src="img/logo/11.jpg" alt="" />
                                </div>
                                <div className="about-shape">
                                    <img src="img/about/about-shape.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="about-right-side pt-55 mb-30">
                                <div className="about-title mb-20">
                                    <h5>{t("about")}</h5>
                                    <h1>{t("aboutrohayat")}</h1>
                                </div>
                                <div className="about-text mb-50">
                                    <p>
                                        {t("abouttext")}
                                    </p>
                                </div>
                                <div className="our-destination">
                                    <div className="single-item mb-30">
                                        <div className="mv-icon f-left">
                                            <img src="img/about/destination-icon-1.png" alt="" />
                                        </div>
                                        <div className="mv-title fix">
                                            <h3>{t("mission")}</h3>
                                            <p>
                                                {t("missiontext")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-item">
                                        <div className="mv-icon f-left">
                                            <img src="img/about/destination-icon-2.png" alt="" />
                                        </div>
                                        <div className="mv-title fix">
                                            <h3>{t("vision")}</h3>
                                            <p>
                                                {t("visiontext")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="appoinment-section pt-120 pb-120 flow">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appoinment-box white">
                                <div className="appoinment-content">
                                    <span className="small-text">{t("about")}</span>
                                    <h1>{t("whatwecando")}</h1>
                                    <p>
                                        {t("missiontext")}
                                    </p>
                                    <ul className="professinals-list pt-30">
                                        <li>
                                            <i className="fa fa-check"></i>
                                            {t("s1")}
                                        </li>
                                        <li>
                                            <i className="fa fa-check"></i>
                                            {t("s2")}
                                        </li>
                                        <li>
                                            <i className="fa fa-check"></i>
                                            {t("s3")}
                                        </li>
                                        <li>
                                            <i className="fa fa-check"></i>
                                            {t("s4")}
                                        </li>
                                    </ul>
                                </div>
                                <a href="/contact" className="btn mt-40">{t("call")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Service />

        </>
    )
}

export default Abouts