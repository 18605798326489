import React, { useEffect, useState } from 'react';
import { useTranslation, getI18n } from "react-i18next";
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import tz from "moment-timezone"
import Iq from "./logoiq.jpg"
import Ku from "./logo.jpg"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper';
import { BiArrowBack } from "react-icons/bi";
import { GrView } from "react-icons/gr";
import parse from 'html-react-parser';
import Header from '../Header';
import Breadcrump from '../../parent/Breadcrump';
import Footer from '../Footer';
function Single() {
    const { t } = useTranslation();

    const lang = getI18n().language;



    const params = useParams();

    const id = params.id;
    // console.log(id);
    const [data, setdata] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_MAIN_URL}safety/safety/`).then((response) => {
            setdata(response.data)
        })
    }, [])


    const [img, setimg] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_MAIN_URL}img/all/`).then((response) => {
            setimg(response.data)
        })
    }, [])


    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            // const view = data.filter(e => e.id == id).map(e => { return e.view + 1 })
            // console.log((view))
            axios.post(`${process.env.REACT_APP_MAIN_URL}safety/view/`, {
                id: id,
                // view: view
            })
        }, 3000);
    }, []);

    const currLang = () => {
        if (lang === "ku") {
            return 1
        }
        if (lang === "ar") {
            return 3
        } else {
            return 1
        }
    }
    // console.log(id)

    return (
        <>
            {/* <Header />
            <Breadcrump /> */}
            <header className='ExtraNav'>
                <h3 onClick={() => navigate(-1)}> <BiArrowBack /> &nbsp;{t("back")}</h3>
                <a href='/'>
                    <img src={lang == 'ku' ? Ku : Iq} className="logo" alt="Logo" style={{ width: "10vmin" }} />
                </a>
            </header>
            <main>
                <section className="blog-area pt-120 pb-80 flow">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="widget mb-40">
                                    <div className="widget-title-box mb-30">
                                        <span className="animate-border"></span>
                                        <h3 className="widget-title">{t("moreblog")}</h3>
                                    </div>
                                    <ul className="recent-posts">

                                        {data.filter(e => e.lang === currLang()).reverse().slice(0, 6).map(e => {
                                            return <li key={e.id}>
                                                <div className="widget-posts-image">
                                                    <a href={`/blog/${e.id}`}><img src={`${process.env.REACT_APP_MAIN_URL}uploads/${e.img}`} alt="" /></a>
                                                </div>
                                                <div className="widget-posts-body">
                                                    <h6 className="widget-posts-title"><a href={`/blog/${e.id}`}>{e.title}</a></h6>
                                                    <div className="widget-posts-meta">{moment.utc(e.dateAdd).tz('Asia/Baghdad').format("MMM D ")} </div>
                                                </div>
                                            </li>
                                        })}


                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-8">


                                {
                                    data.filter(e => e.id == id).map(e => (

                                        <article key={e.id} className="postbox post format-image mb-40">
                                            <div className="blog-inner-img mb-30 mt-30">
                                                <img src={`${process.env.REACT_APP_MAIN_URL}uploads/${e.img}`} alt="blog image" />
                                            </div>
                                            <div className="postbox__text bg-none">
                                                <div className="post-meta mb-15">
                                                    <span><i className="far fa-calendar-check"></i> {moment.utc(e.dateAdd).tz('Asia/Baghdad').format("MMM D ")}</span>
                                                    <span><a href="#!"><i className="far fa-eye"></i> {e.view}</a></span>
                                                    {/* <span><a href="#"><i className="far fa-comments"></i> 23 Comments</a></span> */}
                                                </div>
                                                <h3 className="blog-title">
                                                    {e.title}
                                                </h3>
                                                <div className="post-text mb-20">
                                                    <p>{parse(e.parag)}</p>


                                                    {e.link.length > 0 ? <div className="postbox__video mb-35">
                                                        <iframe style={{ width: "100%", height: "70vh" }} src={`https://www.youtube.com/embed/${e.link}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                    </div>
                                                        : null}

                                                </div>
                                            </div>
                                        </article>
                                    ))
                                }

                                {img.filter(e => e.postType === 1 && e.postId == id).length > 0 ?

                                    <article className="postbox post format-image mb-40">
                                        <h3 className="blog-title">
                                            {t("moreimg")}
                                        </h3>
                                        <Swiper
                                            slidesPerView="1"
                                            spaceBetween={1}

                                            autoplay={{
                                                delay: 1500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={false}
                                            modules={[Autoplay]}
                                            className="mySwiper"
                                        >
                                            {img.filter(e => e.postType === 1 && e.postId == id).map(e => {
                                                return <SwiperSlide key={e.id}>
                                                    <div className="blog-inner-img mb-30 mt-30">
                                                        <img src={`${process.env.REACT_APP_MAIN_URL}${e.src}`} alt="blog image" />
                                                    </div>
                                                </SwiperSlide>
                                            })}
                                        </Swiper>
                                    </article>
                                    : null
                                }



                                <div className="row">
                                    <div className="col-12">
                                        <div className="navigation-border pt-50 mt-40"></div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5">
                                        <div className="bakix-navigation b-next-post text-left mb-30">
                                            <span><a href={`/blog/${id - 1}`}>{t("prevpost")}</a></span>
                                            {/* <h4><a href="#">Tips on Minimalist</a></h4> */}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 ">
                                        <div className="bakix-filter text-left text-md-center mb-30">
                                            <a href="#"><img src="img/icon/filter.png" alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5">
                                        <div className="bakix-navigation b-next-post text-left text-md-right  mb-30">
                                            <span><a href={`/blog/${JSON.parse(id) + 1}`}>{t("nextpost")}</a></span>
                                            {/* <h4><a href="#">Tips on Minimalist</a></h4> */}
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </section>





            </main>













            {/* <header className='ExtraNav'>
                <h3 onClick={() => navigate(-1)}> <BiArrowBack /> &nbsp;{t("backk")}</h3>
                <a href='/'>
                    <img src="/assets/img/logo.png" className="logo" alt="Logo" style={{ width: "45vmin" }} />
                </a>
            </header> */}

            <Footer />
        </>
    )
}

export default Single;