import React from 'react'
import About from './About'
import Blog from './Blog'
import Wearehere from './Wearehere'
import Footer from './Footer'
import Header from './Header'
import Main from './Main'
import Service from './Service'

function Home() {
    return (
        <>

            <Header />
            <main>
                <Main />
                <About />
                <Service />
                <Wearehere />
                <Blog />
            </main>
            <Footer />
        </>
    )
}

export default Home