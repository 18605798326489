import React from 'react'
import { useTranslation } from "react-i18next";

function About() {
    const { t } = useTranslation();

    return (
        <>
            <section className="about-area pt-120 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="medical-icon-brand-2">
                                <img src="img/about/medical-brand-icon-border.png" alt="" />
                            </div>
                            <div className="about-left-side pos-rel mb-30">
                                <div className="about-front-img">
                                    <img src="img/logo/11.jpg" alt="" />
                                </div>
                                <div className="about-shape">
                                    <img src="img/about/about-shape.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 flow">
                            <div className="about-right-side pt-55 mb-30">
                                <div className="about-title mb-20">
                                    <h5>{t("aboutus")}</h5>
                                    <h1>{t("aboutrohayat")}</h1>
                                </div>
                                <div className="about-text">
                                    {t("abouttext")}
                                    <br />
                                </div>
                                <div className="about-author d-flex align-items-center">
                                    <div className="author-ava">
                                        <img src="img/logo/author-ava.png" alt="" />
                                    </div>
                                    <div className="author-desination" style={{ paddingTop: "15px" }}>
                                        <h4>{t("ahmed")}</h4>
                                        <h6 style={{ textAlign: "center" }}>{t("founder")}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About