import React from 'react'
import Breadcrump from '../../parent/Breadcrump'
import Footer from '../Footer'
import Header from '../Header'
import Blogs from './Blogs'
import { useTranslation } from "react-i18next";
function Indexb() {
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <main>
                <Breadcrump title={t("blog")} loc={t("blog")} />

                <Blogs />


            </main>
            <Footer />
        </>
    )
}

export default Indexb