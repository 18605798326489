import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useTranslation, getI18n } from "react-i18next";
import parse from 'html-react-parser';

function Blog() {

    const { t } = useTranslation();

    const [data, setdata] = useState([]);

    // console.log(getI18n().language);
    const lang = getI18n().language;
    // console.log(lang)

    const currLang = () => {
        if (lang === "ku") {
            return 1
        }
        if (lang === "en") {
            return 2
        }
        if (lang === "ar") {
            return 3
        } else {
            return 1
        }
    }


    // console.log(currLang())
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_MAIN_URL}safety/safety/`).then((response) => {
            setdata(response.data)
        })
    }, [])

    return (
        <>
            <section className="latest-news-area pt-115 pb-115 pb-20 flow">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-7">
                            <div className="section-title pos-rel mb-75">
                                <div className="section-icon">
                                    <img
                                        className="section-back-icon back-icon-left"
                                        src="img/section/section-back-icon.png"
                                        alt=""
                                    />
                                </div>
                                <div className="section-text pos-rel">
                                    <h5>{t("blog")}</h5>
                                    <h1>{t("geteveryupdate")}</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="img/shape/section-title-line.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-5 d-none d-lg-block">
                            <div className="section-button text-right pt-80">
                                <a
                                    data-animation="fadeInLeft"
                                    data-delay=".6s"
                                    href="/blog"
                                    className="btn btn-icon ml-0"
                                ><span>+</span>{t("blog")}</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {data.filter(e => e.lang === currLang()).reverse().slice(0, 6).map(e => {
                            return <div key={e.id} className="col-xl-4 col-lg-6 col-md-6">
                                <div className="latest-news-box mb-30">
                                    <div className="latest-news-thumb mb-35">
                                        <a href={`/blog/${e.id}`}>
                                            <img src={`${process.env.REACT_APP_MAIN_URL}uploads/${e.img}`} alt="" />
                                        </a>
                                    </div>
                                    <div className="latest-news-content">
                                        {/* <div className="news-meta mb-10">
                                            <span><a href="#" className="news-tag">Medical,</a></span>
                                            <span><a href="#" className="news-tag">Medicine</a></span>
                                        </div> */}
                                        <h3>
                                            <a href={`/blog/${e.id}`}>
                                                {e.title}
                                            </a>
                                        </h3>
                                        <p>
                                            {parse(e.parag.substring(0, 80) + "...")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog