import React from 'react'
import Breadcrump from '../../parent/Breadcrump'
import Footer from '../Footer'
import Header from '../Header'
import Numbers from './Numbers'
import { useTranslation } from "react-i18next";
function Indexc() {
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <main>
                <Breadcrump title={t("call")} loc={t("call")} />

                <Numbers />

            </main>

            <Footer />

        </>
    )
}

export default Indexc