import React from 'react'
import { useTranslation } from "react-i18next";


function Main() {
    const { t } = useTranslation();

    return (
        <>
            <section className="hero-area">

                <div
                    className=" slider-height d-flex align-items-center"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-8 col-md-10">
                                <div className="hero-text">
                                    <div className="hero-slider-caption flow">

                                        <h1 data-animation="fadeInUp" data-delay=".4s">
                                            {t("rohayat")}
                                        </h1>
                                        <h4 data-animation="fadeInUp" data-delay=".6s">
                                            {t("moto1")}
                                        </h4>
                                        <p data-animation="fadeInUp" data-delay=".6s">
                                            {t("moto")}
                                        </p>
                                    </div>
                                    <div className="hero-slider-btn">
                                        <a
                                            data-animation="fadeInLeft"
                                            data-delay=".6s"
                                            href="/contact"
                                            className="btn btn-icon ml-0"
                                        ><span>+</span>{t("contactus")}</a>
                                        {/* <a
                                                    data-animation="fadeInRight"
                                                    data-delay="1.0s"
                                                    href="https://www.youtube.com/watch?v=eXQgPCsd83c"
                                                    className="play-btn popup-video"
                                                ><i className="fas fa-play"></i
                                                ></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Main





//     < div
// className = "single-slider slider-height d-flex align-items-center"
// data - background="img/slider/slider-bg-2in1.jpg"
//     >
//     <div className="container">
//         <div className="row">
//             <div className="col-xl-6 col-lg-8 col-md-10">
//                 <div className="hero-text">
//                     <div className="hero-slider-caption">
//                         <h5 data-animation="fadeInUp" data-delay=".2s">
//                             We are here for your care.
//                         </h5>
//                         <h1 data-animation="fadeInUp" data-delay=".4s">
//                             Best Care & Better Doctor.
//                         </h1>
//                         <p data-animation="fadeInUp" data-delay=".6s">
//                             Lorem ipsum dolor sit amet, consectetur
//                             adipisicing elit, sed do eiusmod tempor
//                             incididunt ut labore et dolore magna aliqua.
//                         </p>
//                     </div>
//                     <div className="hero-slider-btn">
//                         <a
//                             data-animation="fadeInLeft"
//                             data-delay=".6s"
//                             href="appoinment.html"
//                             className="btn btn-icon ml-0"
//                         ><span>+</span>Make Appointment</a
//                         >
//                         <a
//                             data-animation="fadeInRight"
//                             data-delay="1.0s"
//                             href="https://www.youtube.com/watch?v=eXQgPCsd83c"
//                             className="play-btn popup-video"
//                         ><i className="fas fa-play"></i
//                         ></a>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
//                         </div >