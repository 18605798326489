import React, { useState } from 'react'
import axios from 'axios'

import Nav from './Nav'
// import Slides from './Slides'
import "./admin.css"
import Modal from '../parent/Modal';

function Admin() {
    const [user, setuser] = useState("");
    const [pass, setpass] = useState("");

    const isAuth = localStorage.getItem("adAuth");


    const loginAuth = () => {

        axios.post(`${process.env.REACT_APP_MAIN_URL}img/login/`, {
            user: user,
            pass: pass,

        }).then((res) => {
            console.log(res.data)
            if (res.data.length > 0) {
                localStorage.setItem("adAuth", true);

                window.location.href = "/rohayatblog"
            } else {
                alert("Email Or Password Is Wrong")
            }



        })

        // let id = "admin";
        // let pas = "9897";
        // if (user === id && pass === pas) {
        //     localStorage.setItem("adAuth", true);
        //     window.location.reload(false);
        // } else {
        //     alert("ناوی بەکارهێنەر یان پاسۆرد هەڵەیە")
        // }
    }

    // useEffect(() => {

    //     if (isAuth) {
    //         window.location.href = "/adslider"
    //     }

    // }, [])

    const logout = () => {
        localStorage.removeItem("adAuth")
        window.location.reload(false);
    }


    const updatepost = async () => {

        await axios.put(`${process.env.REACT_APP_MAIN_URL}img/changepass/`, {
            pass: pass


        }).then((response) => {
            if (response.status === 200) {
                alert(" Successfully Updated")
                localStorage.removeItem("adAuth")
                window.location.reload(false)
            }

        });
    }

    return (
        <>
            {
                isAuth ?
                    <>
                        <Nav />
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "65vh", flexDirection: "column" }}>
                            <h3 htmlFor="firstName" className="form-label ">You Logged In Before</h3>
                            <button type="button" className="btn btn-danger btn-lg p-2" onClick={logout}>Logout</button>
                            <button type="button" className="btn btn-warning btn-lg p-2 m-2" data-toggle="modal" data-target="#changepass"> Change Password</button>


                            <Modal title=" Change Password" target="changepass" >
                                <form className="" >
                                    {/* onChange={e => setpriceIQ(e.target.value)} */}
                                    <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>

                                        <div className="col-lg-12">
                                            <label htmlFor="firstName" className="form-label">password</label>
                                            <input type="text" className="form-control" onChange={e => setpass(e.target.value)} id="firstName" placeholder="" required />
                                        </div>

                                        <button type="button" className="btn btn-dark mt-2" data-toggle="modal" data-target="#changechange" > Update</button>
                                        {/* onClick={createExpense} */}
                                    </div>
                                </form>
                            </Modal>


                            <Modal title=" You Sure?" target="changechange" >
                                <form className="" >
                                    {/* onChange={e => setpriceIQ(e.target.value)} */}
                                    <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>

                                        <div className="col-lg-12">
                                            <h3>You Sure To Change Your Password?</h3>
                                        </div>

                                        <button type="button" className="btn btn-dark mt-2" onClick={updatepost} > Update</button>

                                    </div>
                                </form>
                            </Modal>
                        </div>
                    </>
                    :
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "65vh" }}>

                        <div style={{ display: "flex", flexDirection: "column", width: "400px" }}>
                            <div className="col-lg-12 tc">
                                <label htmlFor="firstName" className="form-label ">Name</label>
                                <input type="text" className="form-control" onChange={e => setuser(e.target.value)} required />
                            </div>
                            <div className="col-md-12 tc">
                                <label htmlFor="address" className="form-label">Password</label>
                                <input type="password" onChange={e => setpass(e.target.value)} className="form-control" required />

                            </div>
                            <button type="button" className="btn btn-success btn-sm p-2 m-3" data-toggle="modal" data-target="#deletemodal" onClick={loginAuth}>Login</button>

                        </div>
                    </div>
            }


        </>
    )
}

export default Admin