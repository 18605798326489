import React, { useState, useEffect } from 'react'
import axios from 'axios'
import parse from 'html-react-parser';
import { useTranslation, getI18n } from "react-i18next";

function Blogs() {

    const { t } = useTranslation();
    const lang = getI18n().language;
    // console.log(lang)
    const currLang = () => {
        if (lang === "ku") {
            return 1
        }
        if (lang === "ar") {
            return 3
        } else {
            return 1
        }
    }
    const [data, setdata] = useState([]);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_MAIN_URL}safety/safety/`).then((response) => {
            setdata(response.data)
        })
    }, [])

    return (
        <>

            <section className="blog-area pt-120 pb-120 flow">
                <div className="container">
                    <div className="row">

                        {data.filter(e => e.lang === currLang()).slice(0).reverse().map(e => {
                            return <div key={e.id} className="col-lg-6 col-md-6">
                                <article className="postbox post format-image mb-40">
                                    <div className="postbox__thumb">
                                        <a href={`/blog/${e.id}`}>
                                            <img src={`${process.env.REACT_APP_MAIN_URL}uploads/${e.img}`} alt="blog image" />
                                        </a>
                                    </div>
                                    <div className="postbox__text p-30">

                                        <h3 className="blog-title blog-title-sm">
                                            <a href={`/blog/${e.id}`} >{e.title}</a>
                                        </h3>
                                        <div className="post-text">
                                            <p>{parse(e.parag.substring(0, 100) + "...")}</p>
                                        </div>
                                        <div className="read-more">
                                            <a href={`/blog/${e.id}`} className="read-more">{t("readmore")} <i className="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </article>
                            </div>

                        })}




                    </div>

                </div>
            </section>

        </>
    )
}

export default Blogs