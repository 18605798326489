
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Admin from "./admin/Admin";
// import Conf from "./admin/Conf";
// import Slides from "./admin/Slides";
import Warn from "./admin/Warn";
import './App.css';
import Indexc from './pages/contact/Indexc';
import Indexa from './pages/about/Indexa';
import Indexb from './pages/blog/Indexb';
import Single from './pages/blog/Single';
function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact" element={<Indexc />} />
        <Route exact path="/about" element={<Indexa />} />
        <Route exact path="/blog" element={<Indexb />} />
        <Route exact path="/blog/:id" element={<Single />} />


        <Route exact path="/admin" element={<Admin />} />
        {/* <Route exact path="/adslider" element={<Slides />} /> */}
        <Route exact path="/rohayatblog" element={<Warn />} />
        {/* <Route exact path="/adconf" element={<Conf />} /> */}
      </Routes>

    </>
  );
}

export default App;
