import React from 'react'
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaInstagram, FaYoutube, FaSnapchatGhost, FaTiktok } from "react-icons/fa";
function Numbers() {
    const { t } = useTranslation();

    return (
        <>
            <section className="contact-area pt-120 pb-90" data-background="/img/bg/bg-map.png">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                            <div className="section-title text-center pos-rel mb-75">

                                <div className="section-text pos-rel">
                                    {/* <h5>Departments</h5> */}
                                    <h1>{t("babagdad")}</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="img/shape/section-title-line.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">

                            <div className="contact text-center mb-30">
                                <i className="fas fa-phone"></i>
                                <h3>{t("phone")}</h3>
                                <a href="tel:+9647801514444">0780 151 4444</a>
                            </div>


                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-phone"></i>
                                <h3>{t("phone")}</h3>
                                <a href="tel:+9647700514444">0770 051 4444</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-envelope"></i>
                                <h3>{t("email")}</h3>
                                <p>rohalhayatcenterads@gmail.com</p>
                                {/* <!-- <p>Info@Themepur.com</p> --> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas "><FaFacebookF /></i>
                                <h3>Facebook</h3>
                                <a href="https://www.facebook.com/Rohalhayatcenter/"
                                    target="_blank"
                                    rel="noreferrer"
                                >Rohalhayatcenter</a>
                                {/* <!-- <p>Info@Themepur.com</p> --> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas "><FaInstagram /></i>
                                <h3>Instagram</h3>
                                <a href="https://www.instagram.com/rooh.alhayat.baghdad/"
                                    target="_blank"
                                    rel="noreferrer"
                                >rohalhayatcenter</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas "><FaYoutube /></i>
                                <h3>YouTube</h3>
                                <a href="https://youtube.com/channel/UCqC7vqoYTDuPBmcNu-i5_Lg"
                                    target="_blank"
                                    rel="noreferrer"
                                >Rohalhayatcenter</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="contact text-center mb-30">
                                <i className="fas "><FaSnapchatGhost /></i>
                                <h3>Snapchat</h3>
                                <a href="https://www.snapchat.com/add/rohalhyatcenter?share_id=Nx53GYtKDCE&locale=ar-EG"
                                    target="_blank"
                                    rel="noreferrer"
                                >Rohalhayatcenter</a>
                                {/* <!-- <p>Info@Themepur.com</p> --> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="contact text-center mb-30">
                                <i className="fas "><FaTiktok /></i>
                                <h3>TikTok</h3>
                                <a href="https://tiktok.com/@roohalhayatcenter2"
                                    target="_blank"
                                    rel="noreferrer"
                                >@roohalhayatcenter2</a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                            <div className="section-title text-center pos-rel mb-75">

                                <div className="section-text pos-rel">
                                    {/* <h5>Departments</h5> */}
                                    <h1>{t("bahawler")}</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="img/shape/section-title-line.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">

                            <div className="contact text-center mb-30">
                                <i className="fas fa-phone"></i>
                                <h3>{t("phone")}</h3>
                                <a href="tel:+9647502192244">0750 219 2244</a>
                            </div>


                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-phone"></i>
                                <h3>{t("phone")}</h3>
                                <a href="tel:+9647730154424">0773 015 4424</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-envelope"></i>
                                <h3>{t("email")}</h3>
                                <p>rohalhayatcenterads@gmail.com</p>
                                {/* <!-- <p>Info@Themepur.com</p> --> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas "><FaFacebookF /></i>
                                <h3>Facebook</h3>
                                <a href="https://www.facebook.com/RoohAlhayatCenter/"
                                    target="_blank"
                                    rel="noreferrer"
                                >RoohAlhayatCenter</a>
                                {/* <!-- <p>Info@Themepur.com</p> --> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas "><FaInstagram /></i>
                                <h3>Instagram</h3>
                                <a href="https://www.instagram.com/rooh.alhayat.erbil/"
                                    target="_blank"
                                    rel="noreferrer"
                                >rooh_alhayat_center</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas "><FaYoutube /></i>
                                <h3>YouTube</h3>
                                <a href="https://youtube.com/channel/UCqC7vqoYTDuPBmcNu-i5_Lg"
                                    target="_blank"
                                    rel="noreferrer"
                                >Rohalhayatcenter</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="contact text-center mb-30">
                                <i className="fas "><FaSnapchatGhost /></i>
                                <h3>Snapchat</h3>
                                <a href="https://www.snapchat.com/add/ruh_alhayat2019?share_id=Wwlz1GR-Kp0&locale=ar-EG"
                                    target="_blank"
                                    rel="noreferrer"
                                >ruh_alhayat2019</a>
                                {/* <!-- <p>Info@Themepur.com</p> --> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="contact text-center mb-30">
                                <i className="fas "><FaTiktok /></i>
                                <h3>TikTok</h3>
                                <a href="https://tiktok.com/@roohlhayatcenter"
                                    target="_blank"
                                    rel="noreferrer"
                                >@roohlhayatcenter</a>
                            </div>
                        </div>

                    </div>
                </div>



                <section className="map-area">
                    <div id="contact-map" className="contact-map">
                        <iframe title='Rohayat map' src="https://www.google.com/maps/d/embed?mid=1WEsiu_3ooFbmpOLoj_vVcm7YRdDm874&hl=en&ehbc=2E312F" width="100%" height="100%" ></iframe>
                    </div>
                </section>
            </section>
        </>
    )
}

export default Numbers