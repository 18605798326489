import React from 'react'
import { Link } from 'react-router-dom'
function Nav() {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light flexCenter" dir='ltr'>
                <Link className="navbar-brand" to="/">Home</Link>
                {/* <Link className="navbar-brand" to="/adslider">سڵایدەر</Link> */}
                <Link className="navbar-brand" to="/rohayatblog">Blog</Link>
                {/* <Link className="navbar-brand" to="/adconf">کۆنفڕانس</Link> */}
                <Link className="navbar-brand" to="/admin">Admin</Link>
            </nav>
        </>
    )
}

export default Nav