import React from 'react'

function Modal({ children, title, target }) {
    return (
        <>
            <div className="modal fade" id={target} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title " id="exampleModalLongTitle">{title}</h4>
                            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> */}
                        </div>
                        <div className="modal-body">
                            <p> {children}</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal