import React from 'react'
import { useTranslation, getI18n } from "react-i18next";

function Breadcrump({ img, title, loc }) {
    const { t } = useTranslation();
    return (
        <>
            <section className="breadcrumb-bg pt-200 pb-180 flow">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9">
                            <div className="page-title">
                                <p className="small-text pb-15">{t("wearehelp")}</p>
                                <h1>{title}</h1>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center"
                        >
                            <div className="page-breadcumb">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="/">{t("home")}</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {loc}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Breadcrump