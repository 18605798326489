import React from 'react'
import { useTranslation } from "react-i18next";

function Wearehere() {
    const { t } = useTranslation();
    return (
        <>
            <section className="cta-area pos-rel pt-115 pb-120" >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1 col-md-12">
                            <div className="cta-text text-center">
                                <div className="section-title pos-rel mb-50">
                                    <div className="section-text section-text-white pos-rel">
                                        <h5>{t("weareav")}</h5>
                                        <h1 className="white-color">{t("wearehelp")}</h1>
                                    </div>
                                </div>
                                <div className="section-button section-button-left">
                                    <a
                                        data-animation="fadeInLeft"
                                        data-delay=".6s"
                                        href="/contact"
                                        className="btn btn-icon btn-icon-green ml-0"
                                    ><span>+</span>{t("contactus")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Wearehere