import React, { useState, useEffect } from 'react'
import axios from 'axios'
// import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { BiAddToQueue, BiSelectMultiple } from "react-icons/bi";
import Modal from '../parent/Modal';
import Nav from './Nav';
import moment from 'moment';
import tz from "moment-timezone"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';

function Warn() {
    const isAuth = localStorage.getItem("adAuth");

    const [title, settitle] = useState("");
    const [parag, setparag] = useState("");
    const [image, setimage] = useState("");
    const [YT, setYT] = useState("");
    const [lang, setlang] = useState("");
    const [data, setdata] = useState([]);
    const [mulitple, setmulitple] = useState([]);
    const [ID, setID] = useState([]);

    const onFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('photo', e.target.files[0]);
        axios.post(`${process.env.REACT_APP_MAIN_URL}slider/upload/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                // console.log(res.data);
                alert("Image Uploaded");

                setimage(res.data.file.filename);
            })
    }

    const addIMG = () => {
        // console.log(mulitple)
        axios.post(`${process.env.REACT_APP_MAIN_URL}img/uploadimg/`, {
            type: 1,
            src: mulitple,
            id: ID,
            lang: lang,
        }).then((res) => {
            window.location.reload(false)
            alert("Image Uploaded")
        })
    }

    const deleteData = () => {
        axios.delete(`${process.env.REACT_APP_MAIN_URL}safety/delete/${ID}/`)
            .then((res) => {
                window.location.reload(false)
                alert("Deleted")
            })
    }

    const uploadPicturesHandler = async (e) => {
        const files = e.target.files;

        const formDataU = new FormData();
        for (const key of Object.keys(files)) {
            formDataU.append("photos", files[key]);
        }

        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            const { data } = await axios.post(`${process.env.REACT_APP_MAIN_URL}slider/uploadmul/`, formDataU,
                config
            );
            alert("Images Uploaded");

            setmulitple({ ...mulitple, pictures: data });

        } catch (error) {
            console.error(error);
        }
    };


    const addSlider = () => {
        axios.post(`${process.env.REACT_APP_MAIN_URL}safety/add/`, {
            title: title,
            img: image,
            lang: lang,
            parag: parag,
            link: YT,
        }).then((res) => {
            window.location.reload(false)
            alert("Created")
        })
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_MAIN_URL}safety/safety/`).then((response) => {
            setdata(response.data)
        })
    }, [])

    const updatepost = async () => {
        const langg = data.filter(e => e.id === ID).map(e => e.lang)
        const imgg = data.filter(e => e.id === ID).map(e => e.img)
        const titleg = data.filter(e => e.id === ID).map(e => e.title)
        const paragg = data.filter(e => e.id === ID).map(e => e.parag)
        const linkg = data.filter(e => e.id === ID).map(e => e.link)

        await axios.put(`${process.env.REACT_APP_MAIN_URL}safety/update/`, {
            lang: lang === "" ? langg[0] : lang,
            img: image === "" ? imgg[0] : image,
            title: title === "" ? titleg[0] : title,
            parag: parag === "" ? paragg[0] : parag,
            link: YT === "" ? linkg[0] : YT,
            id: ID


        }).then((response) => {
            if (response.status === 200) {
                alert("Data been updated")
                window.location.reload(false)
            }
            console.log(response)
        });
    }

    const [img, setimg] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_MAIN_URL}img/all/`).then((response) => {
            setimg(response.data)
        })
    }, [])

    const deleteimg = (id) => {
        axios.delete(`${process.env.REACT_APP_MAIN_URL}img/delete/${id}/`)
            .then((res) => {
                window.location.reload(false)
                alert("Image Deleted")
            })
    }

    return (
        <>
            {
                isAuth ?
                    <>
                        <Nav />

                        <div className="col-md-12 col-lg-12 overflow-auto">
                            <h4 className="mb-3 tc">Blogs</h4>
                            <button type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#exampleModalCenter" style={{ backgroundColor: "green" }}><BiAddToQueue /></button>
                        </div>
                        <div className="col-md-12 col-lg-12 overflow-auto" style={{ textAlign: "end" }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Paragraph</th>
                                        <th scope="col">Image</th>

                                        <th scope="col">Date</th>
                                        <th scope="col" className="notPrint"></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {data.slice(0).reverse().map(e => {
                                        return <tr key={e.id} className=''>
                                            <th scope="row">{e.id}</th>
                                            <th scope="row">{e.title}</th>
                                            <th scope="row">{parse(e.parag.substring(0, 250))}</th>
                                            <th scope="row"><img src={`${process.env.REACT_APP_MAIN_URL}uploads/${e.img}`} width="60" height="40" alt="" /></th>
                                            {/* <td>{e.img}</td> */}
                                            {/* http://localhost:3001/uploads/image-1654256710452.jpeg */}

                                            <th scope="row">{moment.utc(e.dateAdd).tz('Asia/Baghdad').format("MMM D YYYY")}</th>
                                            <th scope="row">
                                                <button type="button" onClick={() => setID(e.id)} className="btn btn-success btn-lg p-1" data-toggle="modal" data-target="#multipleimg" style={{ backgroundColor: "goldenrod" }}>
                                                    <BiSelectMultiple />
                                                </button>
                                                <button type="button" onClick={() => setID(e.id)} className="btn btn-warning btn-lg p-1" data-toggle="modal" data-target="#multipleupd" style={{ backgroundColor: "green" }}>
                                                    <BiSelectMultiple />
                                                </button>
                                            </th>

                                            <td className="notPrint">

                                                {/* <button type="button" className="btn btn-warning btn-sm p-1" data-bs-toggle="modal" data-bs-target="#multipleimg" ><BiEditAlt /></button> */}

                                                <button type="button" className="btn btn-warning btn-sm p-1" data-toggle="modal" data-target="#updatemodal" onClick={() => { setID(e.id); setparag(e.parag) }} style={{ backgroundColor: "goldenrod" }}><AiFillEdit /></button>
                                                <button type="button" className="btn btn-danger btn-sm p-1" data-toggle="modal" data-target="#deletemodal" onClick={() => setID(e.id)}><AiFillDelete /></button>

                                            </td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>

                        <Modal title="Post" target="exampleModalCenter" >
                            <form className="" >
                                {/* onChange={e => setpriceIQ(e.target.value)} */}
                                <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                    <td>
                                        <select className='select'
                                            onChange={(e) => setlang(e.target.value)}
                                            required>
                                            <option value="">Choose Language</option>
                                            <option value="3">عربی</option>
                                            <option value="1">کوردی</option>
                                            {/* <option value="2">english</option> */}
                                        </select>
                                    </td>
                                    <div className="col-lg-12">
                                        <label htmlFor="firstName" className="form-label">Title</label>
                                        <input type="text" className="form-control" onChange={e => settitle(e.target.value)} id="firstName" placeholder="" required />
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="firstName" className="form-label">Paragraph</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data=""

                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setparag(data);
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="firstName" className="form-label">Youtube Link</label>
                                        <textarea className="form-control" onChange={e => setYT(e.target.value)} id="firstName" placeholder="" required />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="address" className="form-label">Image</label>
                                        <input type="file" onChange={onFormSubmit} className="form-control" id="address" placeholder="1234 الشارع الأول" required />

                                    </div>
                                    <button type="button" className="btn btn-dark mt-2" onClick={addSlider}> Add</button>
                                    {/* onClick={createExpense} */}
                                </div>
                            </form>
                        </Modal>

                        <Modal title="Add Image" target="multipleimg" >
                            <form className="" >
                                {/* onChange={e => setpriceIQ(e.target.value)} */}
                                <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>

                                    <div className="col-md-12">
                                        <label htmlFor="address" className="form-label">Image</label>
                                        <input type="file" multiple onChange={uploadPicturesHandler} className="form-control" id="address" placeholder="1234 الشارع الأول" required />

                                    </div>
                                    <button type="button" className="btn btn-dark mt-2" onClick={addIMG}> Add</button>
                                    {/* onClick={createExpense} */}
                                </div>
                            </form>
                        </Modal>

                        <Modal title="You Sure?" target="deletemodal" >
                            <form className="" >
                                {/* onChange={e => setpriceIQ(e.target.value)} */}
                                <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>

                                    <h1>You Sure To Delete ?</h1>
                                    <button type="button" className="btn btn-dark mt-2" onClick={deleteData}> Delete</button>
                                    {/* onClick={createExpense} */}
                                </div>
                            </form>
                        </Modal>

                        <Modal title="Update" target="updatemodal" >
                            <form className="" >
                                {/* onChange={e => setpriceIQ(e.target.value)} */}
                                <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                    <td>
                                        <select className='select'
                                            onChange={e => setlang(e.target.value)}
                                            required>
                                            <option value="">Choose Language</option>
                                            <option value="3">عربی</option>
                                            <option value="1">کوردی</option>
                                            {/* <option value="2">english</option> */}
                                        </select>
                                    </td>
                                    <div className="col-lg-12">
                                        <label htmlFor="firstName" className="form-label">Title</label>
                                        <input type="text" className="form-control" onChange={e => settitle(e.target.value)} id="firstName" placeholder={data.filter(e => e.id === ID).map(e => e.title)} required />
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="firstName" className="form-label">Paragraph</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={parag}

                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setparag(data);
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="firstName" className="form-label"> Youtube Link</label>
                                        <textarea className="form-control" onChange={e => setYT(e.target.value)} id="firstName" placeholder={data.filter(e => e.id === ID).map(e => e.link)} required />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="address" className="form-label">Image</label>
                                        <input type="file" onChange={onFormSubmit} className="form-control" id="address" placeholder="1234 الشارع الأول" required />

                                    </div>
                                    <button type="button" className="btn btn-dark mt-2" onClick={updatepost}> Update</button>
                                    {/* onClick={createExpense} */}
                                </div>
                            </form>
                        </Modal>

                        <Modal title="Images" target="multipleupd" >
                            <form className="" >
                                <div className='row'>

                                    {img.filter(e => e.postType === 1 && e.postId == ID).map(e => {
                                        return <div style={{ display: "flex", flexDirection: "column" }} className="col-lg-4"> <img src={`${process.env.REACT_APP_MAIN_URL}${e.src}`} />
                                            <button type="button" className="btn btn-danger btn-sm p-1" onClick={() => deleteimg(e.id)}><AiFillDelete /></button>


                                        </div>
                                    })}


                                </div>
                            </form>
                        </Modal>

                    </>
                    :
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }} ><a href="/admin"><h1>You Must Login</h1></a></div>
            }
        </>
    )
}
export default Warn