import React from 'react'
import { useTranslation, getI18n } from "react-i18next";
import i18next from 'i18next';

function Header() {
    const { t } = useTranslation();
    const langg = getI18n().language;

    // console.log(langg)

    const togglelang = (lang) => {

        if (langg == 'ku') {
            i18next.changeLanguage("ar");
            window.location.reload(false)
        }

        if (langg == 'ar') {
            i18next.changeLanguage("ku");
            window.location.reload(false)
        }


        // i18next.changeLanguage(`${lang}`);
        // window.location.reload(false)
        // console.log(i18next.language)
    };
    return (
        <>

            <header>
                <div className="top-bar d-none d-md-block">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1 col-md-7 offset-md-1">
                                <div className="header-info">
                                    <span><i className="fas fa-phone"></i> {t("enum")}</span>
                                    <span><i className="fas fa-envelope"></i> rohalhayatcenterads@gmail.com</span>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-4">
                                <div className="header-top-right-btn f-right">
                                    <a href="/contact" className="btn">{t("contactus")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-menu-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-5 d-flex align-items-center">
                                <div className="logo logo-circle pos-rel">
                                    <a href="/"><img src={langg == 'ku' ? "img/logo/logo.jpg" : "img/logo/logoiq.jpg"} alt="" /></a>
                                </div>
                                <div className="logo22">
                                    <a href="/"><img src={langg == 'ku' ? "img/logo/logofillku.png" : "img/logo/logofill.png"} alt="" /></a>
                                    {/* <a href="/"><img src="img/logo/logo22.jpg" alt="" /></a> */}
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-9">
                                <div className="header-right f-right">
                                    <div className="header-lang f-right pos-rel d-none d-lg-block">
                                        <div className="lang-icon" onClick={() => togglelang()}>
                                            <img src={langg == 'ku' ? "img/icon/iq.png" : "img/icon/ku.png"} alt="" />
                                            <span>{t("lang")}<i className="fas fa-angle-down"></i></span>
                                        </div>
                                        <ul className="header-lang-list">
                                            <li><a href="#!" onClick={() => togglelang()}>{t("langg")}</a></li>
                                            {/* <li><a href="#">UK</a></li>
                                            <li><a href="#">CA</a></li>
                                            <li><a href="#">AU</a></li> */}
                                        </ul>
                                    </div>
                                    <div className="header-social-icons f-right d-none d-xl-block">
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com/Rohalhayatcenter/"
                                                    target="_blank"
                                                    rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.snapchat.com/add/rohalhyatcenter?share_id=Nx53GYtKDCE&locale=ar-EG"
                                                    target="_blank"
                                                    rel="noreferrer"><i className="fab fa-snapchat"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://youtube.com/channel/UCqC7vqoYTDuPBmcNu-i5_Lg"
                                                    target="_blank"
                                                    rel="noreferrer"><i className="fab fa-youtube"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://instagram.com/rooh_alhayat_center?igshid=YmMyMTA2M2Y="
                                                    target="_blank"
                                                    rel="noreferrer"><i className="fab fa-instagram"></i></a>
                                            </li>
                                            {/* <li>
                                                <a href="#"><i className="fab fa-tiktok"></i></a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="header__menu f-right">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li className='mobshow'>
                                                <a href="#!" onClick={() => togglelang()}>{t("langg")} </a>
                                            </li>
                                            <li>
                                                <a href="/blog">{t("blog")} </a>
                                            </li>
                                            <li>
                                                <a href="/contact">{t("call")} </a>
                                            </li>
                                            <li>
                                                <a href="/about">{t("about")} </a>
                                            </li>
                                            <li>
                                                <a href="/">{t("home")}</a>
                                            </li>
                                            {/* <!-- <li>
                                                <a href="#">Pages +</a>
                                                <ul className="submenu">
                                                    <li><a href="about.html">About</a></li>
                                                    <li><a href="appoinment.html">Appoinment</a></li>
                                                    <li>
                                                        <a href="portfolio-2-col.html"
                                                        >Portfolio 2 column</a
                                                        >
                                                    </li>
                                                    <li>
                                                        <a href="portfolio.html">Portfolio 3 column</a>
                                                    </li>
                                                    <li>
                                                        <a href="portfolio-slider.html"
                                                        >Portfolio Slider</a
                                                        >
                                                    </li>
                                                    <li><a href="contact.html">Contact</a></li>
                                                </ul>
                                            </li> --> */}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mobile-menu"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default Header