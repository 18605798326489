import React from 'react'
import { useTranslation } from "react-i18next";
import ServiceMod from '../parent/ServiceMod';
import { BiHealth } from 'react-icons/bi';
function Service() {
    const { t } = useTranslation();
    return (
        <>
            <section className="servcies-area gray-bg pt-115 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                            <div className="section-title text-center pos-rel mb-75">
                                <div className="section-icon">
                                    <img
                                        className="section-back-icon"
                                        src="img/section/section-back-icon.png"
                                        alt=""
                                    />
                                </div>
                                <div className="section-text pos-rel">
                                    {/* <h5>Departments</h5> */}
                                    <h1>{t("ourservices")}</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="img/shape/section-title-line.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="service-box text-center mb-30">
                                <div className="service-thumb">

                                    <img src="img/services/1.png" alt="" />
                                </div>
                                <div className="service-content" dir="rtl">
                                    <h3><a href="#!" data-toggle="modal" data-target="#s1">{t("s1")}</a></h3>
                                    <p className='more' data-toggle="modal" data-target="#s1">{t("more")} ...</p>


                                    <ServiceMod title={t("s1")} target="s1" >
                                        <div class="modal-body">
                                            {t("s1p")}
                                        </div>
                                    </ServiceMod>


                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="service-box text-center mb-30">
                                <div className="service-thumb">
                                    <img src="img/services/2.png" alt="" />
                                </div>
                                <div className="service-content" dir="rtl">
                                    <h3><a href="#!" data-toggle="modal" data-target="#s2">{t("s2")}</a></h3>
                                    <p className='more' data-toggle="modal" data-target="#s2">{t("more")} ...</p>


                                    <ServiceMod title={t("s2")} target="s2" >
                                        <div class="modal-body">
                                            {t("s2p")}
                                        </div>
                                    </ServiceMod>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="service-box text-center mb-30">
                                <div className="service-thumb">
                                    <img src="img/services/3.png" alt="" />
                                </div>
                                <div className="service-content" dir="rtl">
                                    <h3><a href="#!" data-toggle="modal" data-target="#s3">{t("s3")}</a></h3>
                                    <p className='more' data-toggle="modal" data-target="#s3">{t("more")} ...</p>


                                    <ServiceMod title={t("s3")} target="s3" >
                                        <div class="modal-body">
                                            {t("s3p")}
                                        </div>
                                    </ServiceMod>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="service-box text-center mb-30">
                                <div className="service-thumb">
                                    <img src="img/services/4.png" alt="" />
                                </div>
                                <div className="service-content" dir="rtl">
                                    <h3><a href="#!" data-toggle="modal" data-target="#s4">{t("s4")}</a></h3>
                                    <p className='more' data-toggle="modal" data-target="#s4">{t("more")} ...</p>


                                    <ServiceMod title={t("s4")} target="s4" >
                                        <div class="modal-body">
                                            {t("s4p")}
                                        </div>
                                    </ServiceMod>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="service-box text-center mb-30">
                                <div className="service-thumb">
                                    <img src="img/services/5.png" alt="" />
                                </div>
                                <div className="service-content" dir="rtl">
                                    <h3><a href="#!" data-toggle="modal" data-target="#s5">{t("s5")}</a></h3>
                                    <p className='more' data-toggle="modal" data-target="#s5">{t("more")} ...</p>


                                    <ServiceMod title={t("s5")} target="s5" >
                                        <div class="modal-body">
                                            {t("s5p")}
                                        </div>
                                    </ServiceMod>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="service-box text-center mb-30">
                                <div className="service-thumb">
                                    <img src="img/services/6.png" alt="" />
                                </div>
                                <div className="service-content" dir="rtl">
                                    <h3><a href="#!" data-toggle="modal" data-target="#s6">{t("s6")}</a></h3>
                                    <p className='more' data-toggle="modal" data-target="#s6">{t("more")} ...</p>


                                    <ServiceMod title={t("s6")} target="s6" >
                                        <div class="modal-body">
                                            {t("s6p")}
                                        </div>
                                    </ServiceMod>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="service-box text-center mb-30">
                                <div className="service-thumb">
                                    <img src="img/services/7.png" alt="" />
                                </div>
                                <div className="service-content" dir="rtl">
                                    <h3><a href="#!" data-toggle="modal" data-target="#s7">{t("s7")}</a></h3>
                                    <p className='more' data-toggle="modal" data-target="#s7">{t("more")} ...</p>


                                    <ServiceMod title={t("s7")} target="s7" >
                                        <div class="modal-body">
                                            {t("s7p")}
                                        </div>
                                    </ServiceMod>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="service-box text-center mb-30">
                                <div className="service-thumb">
                                    <img src="img/services/8.png" alt="" />
                                </div>
                                <div className="service-content" dir="rtl">
                                    <h3><a href="#!" data-toggle="modal" data-target="#s8">{t("s8")}</a></h3>
                                    <p className='more' data-toggle="modal" data-target="#s8">{t("more")} ...</p>


                                    <ServiceMod title={t("s8")} target="s8" >
                                        <div class="modal-body">
                                            {t("s8p")}
                                        </div>
                                    </ServiceMod>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button>

            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalCenterTitle">ServiceMod title</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            ...
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    )
}

export default Service