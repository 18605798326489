import React from 'react'
import Breadcrump from '../../parent/Breadcrump'
import Footer from '../Footer'
import Header from '../Header'
import Abouts from './Abouts'
import { useTranslation } from "react-i18next";

function Indexa() {
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <main>
                <Breadcrump title={t("about")} loc={t("about")} />

                <Abouts />

            </main>
            <Footer />

        </>
    )
}

export default Indexa